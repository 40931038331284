'use strict';

Gri.module({
  name: 'footer-alpha',
  ieVersion: null,
  $el: $('.footer-alpha'),
  container: '.footer-alpha',
  fn: function () {
    $('.footer-alpha .scroll a').on("click", function () {
      $("html, body").animate({scrollTop:0}, '1000');
    })
  }
});
